<template>
  <div>
    <div class="userAbout user">

      <b-row>
        <b-col md="12">
          <div class="mb-4 d-flex align-items-center">
            <i class="fas fa-user mr-2"></i>
            <h3>Institutional Profile</h3>
          </div>
        </b-col>
      </b-row>
      <b-row align-v="center" align-h="center">
        <b-col md="3">
          <div class="fName font-weight-bolder mb-1">First Name</div>
          <div class="email font-weight-bolder mb-1">Email</div>
          <div class="mobile font-weight-bolder mb-1">DOB</div>
        </b-col>
        <b-col md="3">
          <div v-if="!profile.id">
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
          </div>
          <div v-else>
            <div class="fName font-weight-bolder mb-1 text-secondary">
              {{ profile.first_name }}
            </div>
            <div
              class="fName font-weight-bolder mb-1 text-secondary d-flex align-items-center"
            >
              {{ profile.email }}
            </div>
             <div class="fName font-weight-bolder mb-1 text-secondary">
              {{ profile.birth_date != null ? profile.birth_date : "-" }}
            </div>
          </div>
        </b-col>
        <b-col md="3">
          <div class="lName font-weight-bolder mb-1">Last Name</div>
          <div class="mobile font-weight-bolder mb-1">Mobile</div>
          <div class="mobile font-weight-bolder mb-1">Username</div>

        </b-col>
        <b-col md="3">
          <div v-if="!profile.id">
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
          </div>
          <div v-else>
            <div class="fName font-weight-bolder mb-1 text-secondary">
              {{ profile.last_name != null ? profile.last_name : "-" }}
            </div>
            <div
              class="fName font-weight-bolder mb-1 text-secondary d-flex align-items-center"
            >
              {{ profile.mobile }}
            </div>
            <div
              class="fName font-weight-bolder mb-1 text-secondary d-flex align-items-center"
            >
              -
            </div>

          </div>
        </b-col>
      </b-row>
    </div>

    <div class="userAddress user mb-4">
       <b-row>
        <b-col md="12">
          <div class="mb-4 d-flex align-items-center">
            <i class="fas fa-address-card mr-2"></i>
            <h3>Address</h3>
          </div>
        </b-col>
      </b-row>
      <b-row align-v="center" align-h="center">
        <b-col md="3">
          <div class="fName font-weight-bolder mb-1">House/Apartment</div>
          <div class="fName font-weight-bolder mb-1">Street</div>
          <div class="email font-weight-bolder mb-1">City</div>
        </b-col>
        <b-col md="3">
          <div v-if="!profile.id">
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>

          </div>
          <div v-else>
            <div class="fName font-weight-bolder mb-1 text-secondary">
              Apartment
            </div>
            <div class="fName font-weight-bolder mb-1 text-secondary">
              {{ profile.address_street != null ? profile.address_street : '-' }}
            </div>
             <div class="fName font-weight-bolder mb-1 text-secondary">
              {{ profile.address_city != null ? profile.address_city : '-' }}
            </div>
          </div>
        </b-col>

         <b-col md="3">
          <div class="fName font-weight-bolder mb-1">State</div>
          <div class="email font-weight-bolder mb-1">Country</div>
          <div class="email font-weight-bolder mb-1">Pincode</div>
        </b-col>

         <b-col md="3">
          <div v-if="!profile.id">
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
          </div>
          <div v-else>
            <div class="fName font-weight-bolder mb-1 text-secondary">
              {{ profile.address_state != null ? profile.address_state : '-' }}
            </div>
            <div
              class="fName font-weight-bolder mb-1 text-secondary d-flex align-items-center"
            >
              {{ profile.address_country != null ? profile.address_country : '-' }}
            </div>
            <div class="fName font-weight-bolder mb-1 text-secondary">
              {{ profile.address_postcode != null ? profile.address_postcode : '-' }}
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="additionInfo">
      <div class="d-flex align-items-center mb-4">
        <i class="fas fa-user mr-2"></i>
        <h3>Collected User Data</h3>
      </div>
      <b-skeleton-table
        :rows="4"
        :columns="2"
        :table-props="{ bordered: true, striped: true }"
        v-if="fetchData"
      ></b-skeleton-table>
      <b-container fluid v-else>
        <b-row align-v="center" no-gutters>
          <b-col md="12" v-if="instituteAttachedQuestions.length == 0">
            <p class="alert alert-danger text-center font-weight-bolder">No Data to Display!</p>
          </b-col>
          <b-col
            md="6"
            v-for="(question, questionIndex) in instituteAttachedQuestions"
            :key="questionIndex"
            v-else
          >
            <p class="ques text-capitalize">
              {{ question.name }}
            </p>
            <p class="ans text-secondary">
              Ans:

              <span v-if="userAnswersObject[question.id]">{{
                userAnswersObject[question.id]
              }}</span>
              <span v-else>-</span>
            </p>

          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
   props: ["profile", "user"],
  async created() {
    this.fetchData = true;

    await this.getAttachedQuestions();
    await this.getUserAnswers();
    this.fetchData = false;
  },
  data() {
    return {
      userDataQuestions: [],
      instituteAttachedQuestions: [],
      userAnswersObject: {},

      fetchData: false,
    };
  },
  methods: {

    async getUserDetails() {
      const url = this.$store.getters.getAPIKey.userDetails;
      await this.$axios.get(url).then((response) => {
        this.userInfo = response.data;
        response;
      });
    },
    async getAttachedQuestions() {
        const profileId = atob(this.$route.params.profileId);
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionusers/${profileId}/institutionuserquestions`;
      await this.$axios
        .get(url)
        .then((res) => {
          let questions = res.data;
          questions.forEach((ques) => {
            this.instituteAttachedQuestions.push({
              id: ques.id,
              description: ques.description,
              name: ques.name,
            });
          });
        })
        .catch((err) => {
          err;
        });
    },
    async getUserAnswers() {
      const profileId = atob(this.$route.params.profileId);
      const url = this.$store.getters.getAPIKey.mainAPI +
        `/institutionusers/${profileId}/institutionuserdatas`;
      await this.$axios
        .get(url)
        .then((response) => {
          const answers = response.data;
          answers.forEach((answer) => {
            this.$set(this.userAnswersObject, answer.question_id, answer.answer);
          });
          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style  scoped>
.additionInfo {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  min-height: 50vh;
  border-top: 5px solid green;
}
.additionInfo .row {
  min-height: 30vh;
}
.additionInfo h3 {
  font-size: 20px !important;
  font-weight: 600;
  text-transform: uppercase;
}
.additionInfo .col-md-6 p {
  margin: 5px;
  padding: 0px;
}
.additionInfo .col-md-6 p.ques,
.additionInfo .col-md-12 p {
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.additionInfo .col-md-6 p.ans {
  font-size: 14px;
  font-weight: 600;
}


.userAbout {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  min-height: 20vh;
  border-top: 5px solid green;
  margin-bottom: 20px;
}

.userAddress {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  min-height: 20vh;
  border-top: 5px solid green;
  margin-top: 20px;
}


.userAbout .col-md-12 {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.userAbout .col-md-3 i {
  font-size: 10px !important;
}
.userAbout .col-md-12 h3,
.userAddress .col-md-12 h3 {
  font-size: 20px !important;
  font-weight: 600;
  text-transform: uppercase;
}

.userAbout .col-md-12 h3,
.userAddress address {
  font-size: 16px;
  font-weight: 600;
}
</style>
